.Home{
    position: relative;
    width: 100%;
    z-index: 9;
    nav{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        position: absolute;
        @media screen and (max-width:500px){
            padding: .5em;
            
        }
        .innerNav{
            width: 100%;
            max-width: 1400px;
            display: flex;
            justify-content: space-between;
            align-items: start;
            padding-top: 1.5em;
            h1{
                color: $main;
                font-size: 2em;
                margin: 0;
                img{
                    width: 120px;
                    @media screen and (max-width:400px){
                        width: 80px;
                    }
                }
                @media screen and (max-width:500px){
                    font-size: 1.5em;
                }
            }
            div{
                display: flex;
                align-items: center;
                a{
                    text-decoration: none;
                    margin: 0 .5em;
                    color: white;
                    font-size: 1.3em;
                    text-transform: capitalize;
                    @media screen and (max-width:500px){
                        font-size: 1em;
                    }
                }
                a:nth-child(1){
                    @media screen and (max-width:500px){
                        display: none;
                    }
                }
                a:nth-child(2){
                    background-color: $main;
                    padding: .5em 2em;
                    border-radius: 50px;
                    display: inline-flex;
                    column-gap: 5px;
                    justify-content: center;
                    align-items: center;
                    
                    svg{
                        font-size: 1.2em;
                    }
                }
            }
        }
    }
    .hero{
        width: 100%;
        height: 100vh;
        position: relative; 
        background-image: linear-gradient( rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8) ),url("../assets/taxi.jpg");
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1;
        color: white;
        h1{
            font-size: 3em;
            text-transform: capitalize;
            margin-bottom: 0;
            @media screen and (max-width:500px){
                text-align: left;
                padding: .5em;
                margin-bottom: 1.5em;
                font-size: 3em;
            }
        }
        p{
            text-transform: capitalize;
            margin-bottom: 2em;
            @media screen and (max-width:500px){
                display: none;
            }
        }
        div{
            column-gap: 10px;
            display: flex;
            position: relative;
            @media screen and (max-width:500px){
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center ;
                padding: 1em;
                row-gap: 10px;
                position: absolute;
                bottom: 2em;
            }
            button , a{
                background-color: $main;
                color: white;
                border: none;
                outline: none;
                font-size: 1.35em;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: .7em 2em;
                text-transform: capitalize;
                border-radius: 50px;
                column-gap: 5px;
                cursor: pointer;
                width: 330px;
                @media screen and (max-width:500px){
                    font-size: 1em;
                    box-sizing: border-box;
                    width: 100%;
                }
            }
            button{
                background-color: white;
                color: $main;
                svg{
                    font-size: 1.3em;
                }
            }
            a{
                background-color: $main;
                svg{
                    font-size: 1.3em;
                }
            }
        }
    }
    .about{
        width: 100%;
        background-color: white;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        @media screen and (max-width:500px){
            padding: .5em;
            display: none;
        }
        .shape1{
            width: 60px;
            position: absolute;
            height: 120px;
            top: 15em;
            left: 0;
            background-image: linear-gradient(to left top, #25d366, #59de7e, #7ce996, #9bf4ad, #b8ffc5);
            z-index: 1;
            border-top-right-radius:50% ;
            border-bottom-right-radius: 80%;
            @media screen and (max-width:500px){
                display: none;
            }
        }
        .shape2{
            width: 60px;
            position: absolute;
            height: 120px;
            top: 15em;
            right: 0;
            background-image: linear-gradient(to left top, #25d366, #59de7e, #7ce996, #9bf4ad, #b8ffc5);
            z-index: 1;
            border-top-left-radius:50% ;
            border-bottom-left-radius: 80%;
            @media screen and (max-width:500px){
                display: none;
            }
        }
        h2{
            font-size: 3.5em;
            color: black;
            margin-bottom: 0;
            text-transform: capitalize;
            z-index: 2;
            @media screen and (max-width:500px){
                font-size: 2em;
                text-align: center;
                margin-bottom: .5em;
            }
        }
        p{
            font-size: 1.3em;
            margin-top: .5em;
            text-transform: capitalize;
            z-index: 2;
            @media screen and (max-width:500px){
                font-size: 1em;
                text-align: center;
                display: none;
            }
        }
        &>div{
            z-index: 2;
            button{
                background-color: black;
                color: white;
                padding: .5em 2em;
                border: none;
                border-radius: 50px;
                font-size: 1.5em;
                text-transform: capitalize;
                display: block;
                margin: 0 auto;
                cursor: pointer;
                transition: transform .3s ease;
                @media screen and (max-width:500px){
                    font-size: 1.3em;
                }
                &:hover{
                    transform: scale(1.08);
                }
            }
            &>div{
                width: 100%;
                display: flex;
                justify-content: center;
                column-gap: 10px;
                row-gap: 10px;
                align-items: center;
                margin-bottom: 1.5em;
                @media screen and (max-width:500px){
                    flex-direction: column;
                }
                div{
                    width: 300px;
                    height: 300px;
                    background-size: cover;
                    color: white;
                    padding: 1em .6em;
                    font-size: 2.5em;
                    border-radius: 20px;
                    text-transform: capitalize;
                    @media screen and (max-width:500px){
                        width: 250px;
                        height: 250px;
                        font-size: 2em;
                    }
                    span{
                        display: block;
                    }
                }
            }
        }
    }
    .booking{
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;
        background-color: $main;
        margin-top: 3em;
        padding: 3em 1em;
        border-radius: 10px;
        background-image: linear-gradient( to right ,black,rgba(0, 0, 0, 0.8) ,transparent ),url("https://images.pexels.com/photos/17345654/pexels-photo-17345654/free-photo-of-map-on-tesla-screen.jpeg");
        background-size: cover;
        @media screen and (max-width:500px){
            border-radius: 0;
            margin-top: 0em;
            
            background-image: linear-gradient( to right ,black,rgba(0, 0, 0, 0.8) ,black ),url("https://images.pexels.com/photos/17345654/pexels-photo-17345654/free-photo-of-map-on-tesla-screen.jpeg");
        }
        h2{
            text-align: center;
            width: 100%;
            font-size: 2.5em;
            text-transform: capitalize;
            color: white;
            margin-top: 0;
            display: flex;
            margin-bottom: 0;
            align-items: center;
            column-gap: 8px;
            justify-content: center;
            @media screen and (max-width:500px){
                font-size: 2em;
                color: white;
                svg{
                    display: none;
                }
            }
        }
        h3{
            text-align: center;
            color: $main;
            margin-top: .3em;
            text-transform: capitalize;
            margin-bottom: 2em;
            @media screen and (max-width:500px){
                font-size: 1em;
                margin-top: .5em;
            }
        }
        form{
            width: 100%;
            display: flex;
            flex-flow: column wrap;
            padding: 0 4em;
            @media screen and (max-width:500px){
                padding: 0;
                flex-direction: column;
                span{
                    flex-direction: column;
                    row-gap: 10px;
                    margin-bottom: 2em;
                    .checkbox-wrapper-9{
                        padding: 1em 1.7em;
                    }
                    div{
                        input{
                            width: 100%;
                        }
                    }
                }
            }
            span{
                display: flex;
                justify-content: center;
                column-gap: 15px;
                width: 100%;
                margin-bottom: 3em;
                div{
                    display: flex;
                    flex-direction: row;
                    font-size: 1em;
                    align-items: center;
                    column-gap: 5px;
                    background-color: white;
                    padding: .2em 1.7em;
                    border-radius: 50px;
                    flex-grow: 1;
                    position: relative;
                    @media screen and (max-width:500px){
                        font-size: .9em;
                    }
                    label{
                        color: black;
                        font-size: 1.15em;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        column-gap: 5px;
                    }
                    input{
                        border: none;
                        outline: none;
                        background-color: white;
                        padding: 1em;
                        flex-grow: 1;
                        border-radius: 10px;
                        text-transform: capitalize;
                        font-size: 1em;
                    }
                    select{
                        width: 100%;
                        border: none;
                        padding: 1em;
                    }
                    .autoComplete{
                        position: absolute;
                        top: 4.5em;
                        z-index: 99;
                        left: 0;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        border-radius: 20px;
                        padding: 1em;
                        row-gap: 10px;
                        max-height: 220px;
                        overflow-y: scroll;
                        &::-webkit-scrollbar {
                            height: 12px;
                            width: 12px;
                            background: #000;
                        }
                        
                        &::-webkit-scrollbar-thumb {
                            background: $main;
                            -webkit-border-radius: 1ex;
                            -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
                        }
                        
                        &::-webkit-scrollbar-corner {
                            background: #000;
                        }
                        .suggsetion{
                            width: 100%;
                            background-color: $main;
                            padding: .5em 1em;
                            cursor: pointer;
                        }
                    }
                }
            }
            button{
                outline: none;
                border: none;
                background-color: $main;
                color: white;
                font-size: 1.25em;
                padding: .8em 1em;
                text-transform: capitalize;
                border-radius: 50px;
                cursor: pointer;
                @media screen and (max-width:500px){
                    font-size: 1.1em;
                }
            }
        }
        
    }
    .bookingSent{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 999;
        background-color: $main;
        font-size: 3em;
        svg{
            font-size: 6em;
            color: white;
        }
        text-transform: capitalize;
        color: white;
        text-align: center;
    }
    .ContactUs{
        width: 100%;
        margin: 0 auto;
        margin-top: 1em;
        padding: 1em;
        max-width: 1330px;
        
        @media screen and (max-width:500px){
            padding: 0;
            margin-top: 0   ;
        }
        .innerContactUs{
            display: flex;
            border-radius: 10px;
            overflow: hidden;
            height: 520px;
            @media screen and (max-width:500px){
                border-radius: 0;
            }
            form{
                flex-grow: 1;
                background-image: linear-gradient(to left top, #25d366, #59de7e, #7ce996, #8ddb9c, #20ed46);
                z-index: 1;
                min-width: 400px;
                @media screen and (max-width:500px){
                    min-width: 100%;
                    padding: 1.5em;background-color: $main;
                }
                display: flex;
                flex-direction: column;
                padding: 2em;
                padding-top: 1em;
                position: relative;
                div{
                    display: flex;
                    flex-direction: column;
                    color: white;
                    margin-bottom: 1em;
                    label{
                        padding-left: .3em;
                        padding-bottom: .3em;
                        display: flex;
                        align-items: center;
                        column-gap: 5px;
                        @media screen and (max-width:500px){
                            font-size: 1.1em;
                            color: black;
                            
                        }
                    }
                    input,textarea {
                        outline: none;
                        border: none;
                        border-radius: 10px;
                        padding: 1em 1.5em;
                    }
                    textarea{
                        height: 150px;
                        border-radius: 10px;
                        resize: none;
                    }
                }
                h1{
                    color: white;
                    margin-top: .5em;
                    text-align: left;
                    margin-bottom: .3em;
                    @media screen and (max-width:500px){
                        text-align: center;
                        color: black;
                        margin-bottom: .6em;
                        margin-top: 0;
                    }
                }
                .submitInContact{
                    position: relative;
                    bottom: 1em;
                    display: block;
                    width: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: white;
                    outline: none;
                    border: none;
                    border-radius: 10px;
                    padding: 1em;
                    margin-top: 1em;
                    cursor: pointer;
                    @media screen and (max-width:500px){
                        background-color: black;
                        color: $main;
                    }
                }
            }
            .contactUsSus{
                flex-grow: 2;
                padding-top: 2em;
                padding-left: 4em;
                position: relative;
                overflow: hidden;
                h1{
                    color: white;
                    font-size: 5em;
                    max-width: 700px;
                    line-height: 1.4em;
                    text-transform: capitalize;
                    
                }
                background: radial-gradient(at right center, rgb(0, 0, 0), rgb(0, 0, 0));
                span{
                    position: absolute;
                    width: 200px;
                    height: 200px;
                    background-color: white;
                    outline: 10px dashed black;
                    border-radius: 555px;
                    top: -5em;
                    right: -75px;
                    filter: blur(230px);
                }
                div{
                    width: 250px;
                    height: 200px;
                    position: absolute;
                    border: 70px solid rgba(255, 255, 255, 0.09);
                    bottom: -3.8em;
                    left: -5.8em;
                    border-radius: 500px;
                    transform: rotate(-40deg);
                }
                @media screen and (max-width:700px){
                        display: none;
                    }
            }
        }
    }
    .ContactInfos{
        width: 100%;
        background-color: black;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: .8em;
        @media screen and (max-width:500px){
            flex-direction: column;
            padding: 1em 0;
            margin-top: 0;
        }
        p{
            color: white;
            display: flex;
            align-items: center;
            column-gap: 5px;
            font-size: 1.2em;
            text-transform: capitalize;
            @media screen and (max-width:500px){
                text-align: left;
                width: 100%;
                padding-left: .5em;
                column-gap: 10px;
                margin: .7em 0;
            }
            svg{
                color: $main;
                font-size: 1.25em;
            }
        }
    }
    .pricing{
        width: 100%;
        max-width: 1300px;
        margin: 0 auto;
        border-radius: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3em;
        row-gap: 10px;
        border: 0px solid black;
        padding-top: 3.5em;
        padding-bottom: 3.5em;
        background-image: linear-gradient(to left top, #25d366, #59de7e, #7ce996, #9bf4ad, #b8ffc5);
        @media screen and (max-width:500px){
            margin-top: 0;
            border-radius: 0;
            padding-top: 1em;
            align-items: initial;
            background-color: $main;
            background-image: none;
            padding-bottom: 0;
        }
        &>h1{
            font-size: 5em;
            text-transform: capitalize;
            margin-bottom: .15em;
            margin-top: 0;
            @media screen and (max-width:500px){
                font-size: 2.7em;
                text-align: center;
                color: white;
            }
        }
        overflow: hidden;
        &>span{
            width: 150px;
            height: 150px;
            border-radius: 80px;
            position: absolute;
            top: -3em;
            left: -3em;
            display: block;
            border: 40px solid white;
            background-color: transparent  ;
            @media screen and (max-width:500px){
                display: none;
            }
        }
        .pricingHolder{
            display: flex;
            justify-content: center;
            column-gap: 10px;
            row-gap: 10px;
            @media screen and (max-width:500px){
                overflow-x: scroll;
                scrollbar-width: 0px;
                &::-webkit-scrollbar{
                    display: none;
                }
                justify-content: start;
                contain: content;
                display: flex;
                flex-flow: row;
                max-height: 403px;
                overflow-x: scroll;
                overflow-y: hidden;
                position: relative;
                scrollbar-width: none;
                padding: 1em;
                padding-top: 0;
            }
            div{
                width: 300px;
                height: 350px;
                background-size: cover;
                color: white;
                padding: 2em;
                font-size: 1em;
                border-radius: 20px;
                text-transform: capitalize;
                background-color: black;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                @media screen and (max-width:500px){
                    min-width: 300px;
                    min-width: 220px;
                    height: 226px;
                }
                span{
                    position: absolute;
                    top: -1em;
                    width: 100px;
                    height: 350px;
                    background-color: rgb(37 211 102 / 15%);
                    border-radius: 20px;
                    left: 1.2em;
                    display: block;

                }
                label{
                    font-size: 5em;
                    margin-bottom:1em;
                    display: block;
                    color:$main;
                    position: relative;
                    z-index: 99;
                    @media screen and (max-width:500px){
                        color: white;
                        font-size: 3em;
                        margin-bottom: .5em;
                    }
                }
                p{
                    font-size: 1.7em;
                    margin-bottom: 0;
                    margin-top: .3em;
                    display: flex;
                    align-items: center;
                    column-gap: 5px;
                    position: relative;
                    z-index: 99;
                    @media screen and (max-width:500px){
                        font-size: 1.2em;
                        margin-bottom: 0;
                        margin-top: 0.3em;
                        display: flex;
                        align-items: center;
                        column-gap: 5px;
                        position: relative;
                        z-index: 99;
                    }
                    svg{
                        color: $main;
                    }
                }
            }
        }
        .pricingHolder2{
            width: 100%;
            display: flex;
            column-gap: 10px;
            max-width: 920px;
            @media screen and (max-width:500px){
                flex-direction: column;
            }
        }
        .pracingHeureHolder{
            width: 100%;
            max-width: 920px;
            height: 300px;
            background-size: cover;
            color: white;
            padding: 2em;
            font-size: 1em;
            border-radius: 20px;
            text-transform: capitalize;
            background-color: black;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            &:nth-child(1){
                border-bottom: 1px solid $main;
            }
            @media screen and (max-width:500px){
                border-radius: 0;
                height: 178px;
            }
            span{
                position: absolute;
                top: -1em;
                width: 100px;
                height: 290px;
                background-color: rgb(37 211 102 / 15%);
                border-radius: 20px;
                left: 1.5em;
                display: block;
                @media screen and (max-width:500px){
                    left: 2em;
                }
            }
            label{
                font-size: 3.5em;
                margin-bottom:1.5em;
                display: block;
                color:$main;
                position: relative;
                z-index: 99;
                @media screen and (max-width:500px){
                    font-size: 2em;
                    text-align: center;
                    margin-bottom: .5em;
                    color: white;
                }
            }
            p{
                font-size: 2.3em;
                margin-bottom: 0;
                margin-top: .3em;
                display: flex;
                align-items: center;
                column-gap: 10px;
                position: relative;
                z-index: 99;
                @media screen and (max-width:500px){
                    font-size: 1.5em;
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    text-align: center;
                }
                svg{
                    color: $main;
                    @media screen and (max-width:500px){
                        
                    }
                }
            }
        }
    }
}


.loader{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: black;
    z-index: 9999;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 200px;
        animation: scaleUpDown 2s infinite; /* Apply the animation to the image */
    }
}
@keyframes scaleUpDown {
    0%, 100% {
      transform: scale(1); /* Start and end at original size */
    }
    50% {
      transform: scale(1.1); /* Scale up to 1.5 times the original size */
    }
  }
// added css to make the checkbox
.checkbox-wrapper-9 .tgl {
    display: none;
  }
  .checkbox-wrapper-9 .tgl,
  .checkbox-wrapper-9 .tgl:after,
  .checkbox-wrapper-9 .tgl:before,
  .checkbox-wrapper-9 .tgl *,
  .checkbox-wrapper-9 .tgl *:after,
  .checkbox-wrapper-9 .tgl *:before,
  .checkbox-wrapper-9 .tgl + .tgl-btn {
    box-sizing: border-box;
  }
  .checkbox-wrapper-9 .tgl::-moz-selection,
  .checkbox-wrapper-9 .tgl:after::-moz-selection,
  .checkbox-wrapper-9 .tgl:before::-moz-selection,
  .checkbox-wrapper-9 .tgl *::-moz-selection,
  .checkbox-wrapper-9 .tgl *:after::-moz-selection,
  .checkbox-wrapper-9 .tgl *:before::-moz-selection,
  .checkbox-wrapper-9 .tgl + .tgl-btn::-moz-selection,
  .checkbox-wrapper-9 .tgl::selection,
  .checkbox-wrapper-9 .tgl:after::selection,
  .checkbox-wrapper-9 .tgl:before::selection,
  .checkbox-wrapper-9 .tgl *::selection,
  .checkbox-wrapper-9 .tgl *:after::selection,
  .checkbox-wrapper-9 .tgl *:before::selection,
  .checkbox-wrapper-9 .tgl + .tgl-btn::selection {
    background: none;
  }
  .checkbox-wrapper-9 .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 1.5em;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .checkbox-wrapper-9 .tgl + .tgl-btn:after,
  .checkbox-wrapper-9 .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
  }
  .checkbox-wrapper-9 .tgl + .tgl-btn:after {
    left: 0;
  }
  .checkbox-wrapper-9 .tgl + .tgl-btn:before {
    display: none;
  }
  .checkbox-wrapper-9 .tgl:checked + .tgl-btn:after {
    left: 50%;
  }

  .checkbox-wrapper-9 .tgl-flat + .tgl-btn {
    padding: 2px;
    transition: all 0.2s ease;
    background: #fff;
    border: 4px solid black;
    border-radius: 2em;
    margin: 0 auto;
  }
  .checkbox-wrapper-9 .tgl-flat + .tgl-btn:after {
    transition: all 0.2s ease;
    background: black;
    content: "";
    border-radius: 1em;
  }
  .checkbox-wrapper-9 .tgl-flat:checked + .tgl-btn {
    border: 4px solid $main;
  }
  .checkbox-wrapper-9 .tgl-flat:checked + .tgl-btn:after {
    left: 50%;
    background: $main;
  }