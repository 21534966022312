@import url('https://fonts.googleapis.com/css2?family=Radio+Canada+Big:ital,wght@0,600;1,600&display=swap');

$main:#25d366;

*{
    font-family: "Radio Canada Big", sans-serif;
    font-optical-sizing: auto;
    box-sizing: border-box;
}
html{
    scroll-behavior: smooth;
}
body{
    margin: 0;
}


@import "Home";
@import "Admin";