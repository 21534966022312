.adminLogin{
    width: 100%;
    background-color: $main;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
    h1{
        font-size: 4em;
        margin-top: 0;
        @media screen and (max-width:500px){
            text-align: center;
            font-size: 2em;
        }
    }
    svg{
        font-size: 10em;
        @media screen and (max-width:500px){
            font-size: 7em;
        }
    }
    form{
        background-color: white;
        border-radius: 50px;
        padding: .5em;
        display: flex;
        input{
            font-size: 1em;
            width: 100%;
            min-width: 550px;
            padding: .5em;
            outline: none;
            border: none;
            border-radius: 50px;
            padding-left: 1em;
            text-transform: capitalize;
            @media screen and (max-width:500px){
                min-width: unset;
            }
        }
        button{
            background-color: $main;
            padding: 1em 2em;
            outline: none;
            border: none;
            width: 200px;
            border-radius: 50px;
            cursor: pointer;
        }
    }
}
.AdminDash{
    width: 100%;
    position: relative;
    display: flex;
    height: 100vh;
    @media screen and (max-width:850px){
        flex-direction: column;
    }
    .sideBar{
        width: 348px;
        height: 100vh;
        padding: 1em 1.5em;
        background-color: $main;
        position: relative;
        h1{
            color: black;
            text-align: center;
            font-size: 3em;
        }
        div{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 10px;
            margin-top: 3em;
            a{
                background-color: black;
                width: 100%;
                color: white;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: left;
                padding: 1em;
                border-radius: 10px;
                column-gap: 10px;
                font-size: 1.2em;
                svg{
                    font-size: 1.5em;
                }
            }
        }
        h2{
            position: absolute;
            bottom: 0;
            color: black;
            font-size: .8em;
            text-align: center;
            left: 0;
            text-transform: capitalize;
            width: 100%;
        }
        @media screen and (max-width:850px){
            position: absolute;
            left: -400px;
            bottom: 0;
        }
    }
    .mobileNavBar{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: black;
        padding: .2em .5em;
        @media screen and (min-width:850px){
            display: none;
        }
        h1{
            color: white;
            font-size: 1.1em;
            
        }
        div{
            display: flex;
            column-gap: 10px;
            a{
                font-size: .95em;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                color: black;
                text-decoration: none;
                padding: .5em;
                border-radius: 5px;
                column-gap: 3px;
            }
        }
    }
    .requestsHolder{
        flex-grow: 1;
        padding: 1em;
        height: 100vh;
        scrollbar-width: 0px;
        &::-webkit-scrollbar{
            display: none;
        }
        overflow-y: scroll;
        .header{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: space-between;
            padding-bottom: .5em;
            h1{
                background-color: black;
                color: white;
                padding: .35em 1em;
                border-radius: 10px;
                font-size: 1.5em;
                flex-grow: 1;
                text-align: center;
                margin: 0;
            }
            div{
                @media screen and (max-width:850px){
                    display: flex;
                }
                button{
                    background-color: black;
                    color: white;
                    text-align: center;
                    outline: none;
                    border: none;
                    padding: .5em 1em;
                    height: 100%;
                    font-size: 1.1em;
                    margin: 0 .2em;
                    border-radius: 10px;
                    text-transform: capitalize;
                    cursor: pointer;
                    transition: background-color .3s ease;
                    display: inline-flex;
                    column-gap: 5px;
                    align-items: center;
                    &:hover{
                        background-color: $main;
                    }
                    @media screen and (max-width:850px){
                        flex-grow: 1;
                    }
                }
            }
            @media screen and (max-width:850px){
                flex-direction: column;
                row-gap: 10px;
            }
        }
        .searchSection{
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: black;
            padding-bottom: .5em;
            border-radius: 10px;
            padding: 2em 1em;
            h1{
                font-size: 4em;
                text-transform: capitalize;
                color: white;
                margin-top: 0;
                margin-bottom: .1em;
                @media screen and (max-width:850px){
                    font-size: 1.3em;
                }
            }
            div{
                width: 100%;
                display: flex;
                background-color: white;
                max-width: 1000px;
                padding: .5em 1em;
                border-radius: 10px;
                align-items: center;
                svg{
                    color: black;
                    font-size: 2em;
                }
                input{
                    flex-grow: 1;
                    outline: none;
                    border: none;
                    padding-left: 1em;
                    text-transform: capitalize;
                    @media screen and (max-width:850px){
                        font-size: .75em;
                    }
                }
                select{
                    outline: none;
                    border: none;
                    font-size: 1em;
                    background-color: $main;
                    padding: .5em .5em;
                    border-radius: 10px;
                    appearance: none;
                    text-align: center;
                    @media screen and (max-width:850px){
                        font-size: .7em;
                    }
                }
            }
        }
        .reqCardsHolder{
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            border-radius: 10px;
            margin: 0;
            padding: .5em 0em;
            justify-content: center;
            align-items: center;
            list-style: none;
            column-gap: 10px;
            row-gap: 10px;
            li{
                margin: 0;
                padding: 1em;
                background-color: black;
                border-radius: 10px;
                width: 100%;
                max-width: 596px;
                color: white;
                .strong{
                    width: 100%;
                    display: flex;
                    column-gap: 5px;
                    label{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $main;
                        border-radius: 5px;
                        padding: 5px 10px;
                        color: black;
                        column-gap: 5px;
                        font-size: 1.1em;

                    }
                    label:nth-child(2){
                        @media screen and (max-width:850px){
                            font-size: .75em;
                        }
                    }
                    label:nth-child(1){
                        flex-grow: 1;
                        text-align: center;
                    }
                }
                .underStrong{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    label{
                        display: flex;
                        align-items: center;
                        column-gap: 10px;
                        font-size: 1.2em;
                        svg{
                            color: $main;
                        }
                    }
                }
                .inCar{
                    width: 100%;
                    display: flex;
                    column-gap: 5px;
                    margin-bottom: 10px;
                    label{
                        background-color: $main;
                        flex-grow: 1;
                        display: flex;
                        color: black;
                        padding: 5px;
                        border-radius: 5px;
                        column-gap: 3px;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.1em;
                    }
                }
                .aboutUser{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 10px;
                    label{
                        display: flex;
                        align-items: center;
                        column-gap: 10px;
                        font-size: 1.2em;
                        svg{
                            color: $main;
                        }
                    }
                }
                .doneBtn{
                    width: 100%;
                    background-color: white;
                    outline: 0;
                    border: none;
                    border-radius: 5px;
                    padding: 7px;
                    text-transform: capitalize;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    column-gap: 5px;
                    font-size: 1.05em;
                    cursor: pointer;
                    transition: background-color .3s ease;
                    &:hover{
                        background-color: $main;
                        color: white;
                    }
                    svg{
                        font-size: 1.4em;
                    }
                }
            }
        }
    }
    .messagesHolder{
        width: 100%;
        padding: 1em;
        & > h1 {
            background-color: black;
            text-align: center;
            border-radius: 10px;
            color: white;
            padding: .5em;
            margin: 0;
            font-size: 1.8em;
            margin-bottom: 10px;
        }
        .searchSection{
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: black;
            padding-bottom: .5em;
            border-radius: 10px;
            padding: 2em 1em;
            h1{
                font-size: 4em;
                text-transform: capitalize;
                color: white;
                margin-top: 0;
                margin-bottom: .1em;
                @media screen and (max-width:850px){
                    font-size: 1.3em;
                }
            }
            div{
                width: 100%;
                display: flex;
                background-color: white;
                max-width: 1000px;
                padding: .5em 1em;
                border-radius: 10px;
                align-items: center;
                svg{
                    color: black;
                    font-size: 2em;
                }
                input{
                    flex-grow: 1;
                    outline: none;
                    border: none;
                    padding-left: 1em;
                    text-transform: capitalize;
                    @media screen and (max-width:850px){
                        font-size: .75em;
                    }
                }
                select{
                    outline: none;
                    border: none;
                    font-size: 1em;
                    background-color: $main;
                    padding: .5em .5em;
                    border-radius: 10px;
                    appearance: none;
                    text-align: center;
                    @media screen and (max-width:850px){
                        font-size: .7em;
                    }
                }
            }
        }
    }
    .msgCardsHolder{
        width: 100%;
        list-style: none;
        padding: 0;
        li{
            width: 100%;
            max-width: 350px;
            background-color: black;
            border-radius: 10px;
            color: white;
            padding: 1em;
            .aboutUser{
                display: flex;
                flex-direction: column;
                row-gap: 5px;
                label{
                    display: flex;
                    align-items: center;
                    column-gap: 5px;
                    background-color: $main;
                    padding: .5em .5em;
                    border-radius: 5px;
                    color: black;
                    svg{
                        color: white;
                    }
                }
            }
            .messageContent{
                label{
                    display: flex;
                    align-items: center;
                    column-gap: 5px;
                    background-color: $main;
                    padding: .5em .5em;
                    border-radius: 5px;
                    color: black;
                    svg{
                        color: white;
                    }
                }
            }
            .readBtn{
                width: 100%;
                background-color: white;
                color: black;
                outline: none;
                border: none;
                padding: .5em;
                border-radius: 5px;
                margin-top: 5px;
                cursor: pointer;
            }
        }
    }
}