@import url("https://fonts.googleapis.com/css2?family=Radio+Canada+Big:ital,wght@0,600;1,600&display=swap");
* {
  font-family: "Radio Canada Big", sans-serif;
  font-optical-sizing: auto;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

.Home {
  position: relative;
  width: 100%;
  z-index: 9;
}
.Home nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: absolute;
}
@media screen and (max-width: 500px) {
  .Home nav {
    padding: 0.5em;
  }
}
.Home nav .innerNav {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-top: 1.5em;
}
.Home nav .innerNav h1 {
  color: #25d366;
  font-size: 2em;
  margin: 0;
}
.Home nav .innerNav h1 img {
  width: 120px;
}
@media screen and (max-width: 400px) {
  .Home nav .innerNav h1 img {
    width: 80px;
  }
}
@media screen and (max-width: 500px) {
  .Home nav .innerNav h1 {
    font-size: 1.5em;
  }
}
.Home nav .innerNav div {
  display: flex;
  align-items: center;
}
.Home nav .innerNav div a {
  text-decoration: none;
  margin: 0 0.5em;
  color: white;
  font-size: 1.3em;
  text-transform: capitalize;
}
@media screen and (max-width: 500px) {
  .Home nav .innerNav div a {
    font-size: 1em;
  }
}
@media screen and (max-width: 500px) {
  .Home nav .innerNav div a:nth-child(1) {
    display: none;
  }
}
.Home nav .innerNav div a:nth-child(2) {
  background-color: #25d366;
  padding: 0.5em 2em;
  border-radius: 50px;
  display: inline-flex;
  -moz-column-gap: 5px;
       column-gap: 5px;
  justify-content: center;
  align-items: center;
}
.Home nav .innerNav div a:nth-child(2) svg {
  font-size: 1.2em;
}
.Home .hero {
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0.8)), url("../assets/taxi.jpg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  color: white;
}
.Home .hero h1 {
  font-size: 3em;
  text-transform: capitalize;
  margin-bottom: 0;
}
@media screen and (max-width: 500px) {
  .Home .hero h1 {
    text-align: left;
    padding: 0.5em;
    margin-bottom: 1.5em;
    font-size: 3em;
  }
}
.Home .hero p {
  text-transform: capitalize;
  margin-bottom: 2em;
}
@media screen and (max-width: 500px) {
  .Home .hero p {
    display: none;
  }
}
.Home .hero div {
  -moz-column-gap: 10px;
       column-gap: 10px;
  display: flex;
  position: relative;
}
@media screen and (max-width: 500px) {
  .Home .hero div {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    row-gap: 10px;
    position: absolute;
    bottom: 2em;
  }
}
.Home .hero div button, .Home .hero div a {
  background-color: #25d366;
  color: white;
  border: none;
  outline: none;
  font-size: 1.35em;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7em 2em;
  text-transform: capitalize;
  border-radius: 50px;
  -moz-column-gap: 5px;
       column-gap: 5px;
  cursor: pointer;
  width: 330px;
}
@media screen and (max-width: 500px) {
  .Home .hero div button, .Home .hero div a {
    font-size: 1em;
    box-sizing: border-box;
    width: 100%;
  }
}
.Home .hero div button {
  background-color: white;
  color: #25d366;
}
.Home .hero div button svg {
  font-size: 1.3em;
}
.Home .hero div a {
  background-color: #25d366;
}
.Home .hero div a svg {
  font-size: 1.3em;
}
.Home .about {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 500px) {
  .Home .about {
    padding: 0.5em;
    display: none;
  }
}
.Home .about .shape1 {
  width: 60px;
  position: absolute;
  height: 120px;
  top: 15em;
  left: 0;
  background-image: linear-gradient(to left top, #25d366, #59de7e, #7ce996, #9bf4ad, #b8ffc5);
  z-index: 1;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 80%;
}
@media screen and (max-width: 500px) {
  .Home .about .shape1 {
    display: none;
  }
}
.Home .about .shape2 {
  width: 60px;
  position: absolute;
  height: 120px;
  top: 15em;
  right: 0;
  background-image: linear-gradient(to left top, #25d366, #59de7e, #7ce996, #9bf4ad, #b8ffc5);
  z-index: 1;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 80%;
}
@media screen and (max-width: 500px) {
  .Home .about .shape2 {
    display: none;
  }
}
.Home .about h2 {
  font-size: 3.5em;
  color: black;
  margin-bottom: 0;
  text-transform: capitalize;
  z-index: 2;
}
@media screen and (max-width: 500px) {
  .Home .about h2 {
    font-size: 2em;
    text-align: center;
    margin-bottom: 0.5em;
  }
}
.Home .about p {
  font-size: 1.3em;
  margin-top: 0.5em;
  text-transform: capitalize;
  z-index: 2;
}
@media screen and (max-width: 500px) {
  .Home .about p {
    font-size: 1em;
    text-align: center;
    display: none;
  }
}
.Home .about > div {
  z-index: 2;
}
.Home .about > div button {
  background-color: black;
  color: white;
  padding: 0.5em 2em;
  border: none;
  border-radius: 50px;
  font-size: 1.5em;
  text-transform: capitalize;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  transition: transform 0.3s ease;
}
@media screen and (max-width: 500px) {
  .Home .about > div button {
    font-size: 1.3em;
  }
}
.Home .about > div button:hover {
  transform: scale(1.08);
}
.Home .about > div > div {
  width: 100%;
  display: flex;
  justify-content: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 10px;
  align-items: center;
  margin-bottom: 1.5em;
}
@media screen and (max-width: 500px) {
  .Home .about > div > div {
    flex-direction: column;
  }
}
.Home .about > div > div div {
  width: 300px;
  height: 300px;
  background-size: cover;
  color: white;
  padding: 1em 0.6em;
  font-size: 2.5em;
  border-radius: 20px;
  text-transform: capitalize;
}
@media screen and (max-width: 500px) {
  .Home .about > div > div div {
    width: 250px;
    height: 250px;
    font-size: 2em;
  }
}
.Home .about > div > div div span {
  display: block;
}
.Home .booking {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  background-color: #25d366;
  margin-top: 3em;
  padding: 3em 1em;
  border-radius: 10px;
  background-image: linear-gradient(to right, black, rgba(0, 0, 0, 0.8), transparent), url("https://images.pexels.com/photos/17345654/pexels-photo-17345654/free-photo-of-map-on-tesla-screen.jpeg");
  background-size: cover;
}
@media screen and (max-width: 500px) {
  .Home .booking {
    border-radius: 0;
    margin-top: 0em;
    background-image: linear-gradient(to right, black, rgba(0, 0, 0, 0.8), black), url("https://images.pexels.com/photos/17345654/pexels-photo-17345654/free-photo-of-map-on-tesla-screen.jpeg");
  }
}
.Home .booking h2 {
  text-align: center;
  width: 100%;
  font-size: 2.5em;
  text-transform: capitalize;
  color: white;
  margin-top: 0;
  display: flex;
  margin-bottom: 0;
  align-items: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
  justify-content: center;
}
@media screen and (max-width: 500px) {
  .Home .booking h2 {
    font-size: 2em;
    color: white;
  }
  .Home .booking h2 svg {
    display: none;
  }
}
.Home .booking h3 {
  text-align: center;
  color: #25d366;
  margin-top: 0.3em;
  text-transform: capitalize;
  margin-bottom: 2em;
}
@media screen and (max-width: 500px) {
  .Home .booking h3 {
    font-size: 1em;
    margin-top: 0.5em;
  }
}
.Home .booking form {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  padding: 0 4em;
}
@media screen and (max-width: 500px) {
  .Home .booking form {
    padding: 0;
    flex-direction: column;
  }
  .Home .booking form span {
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 2em;
  }
  .Home .booking form span .checkbox-wrapper-9 {
    padding: 1em 1.7em;
  }
  .Home .booking form span div input {
    width: 100%;
  }
}
.Home .booking form span {
  display: flex;
  justify-content: center;
  -moz-column-gap: 15px;
       column-gap: 15px;
  width: 100%;
  margin-bottom: 3em;
}
.Home .booking form span div {
  display: flex;
  flex-direction: row;
  font-size: 1em;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
  background-color: white;
  padding: 0.2em 1.7em;
  border-radius: 50px;
  flex-grow: 1;
  position: relative;
}
@media screen and (max-width: 500px) {
  .Home .booking form span div {
    font-size: 0.9em;
  }
}
.Home .booking form span div label {
  color: black;
  font-size: 1.15em;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
.Home .booking form span div input {
  border: none;
  outline: none;
  background-color: white;
  padding: 1em;
  flex-grow: 1;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 1em;
}
.Home .booking form span div select {
  width: 100%;
  border: none;
  padding: 1em;
}
.Home .booking form span div .autoComplete {
  position: absolute;
  top: 4.5em;
  z-index: 99;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 1em;
  row-gap: 10px;
  max-height: 220px;
  overflow-y: scroll;
}
.Home .booking form span div .autoComplete::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: #000;
}
.Home .booking form span div .autoComplete::-webkit-scrollbar-thumb {
  background: #25d366;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}
.Home .booking form span div .autoComplete::-webkit-scrollbar-corner {
  background: #000;
}
.Home .booking form span div .autoComplete .suggsetion {
  width: 100%;
  background-color: #25d366;
  padding: 0.5em 1em;
  cursor: pointer;
}
.Home .booking form button {
  outline: none;
  border: none;
  background-color: #25d366;
  color: white;
  font-size: 1.25em;
  padding: 0.8em 1em;
  text-transform: capitalize;
  border-radius: 50px;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .Home .booking form button {
    font-size: 1.1em;
  }
}
.Home .bookingSent {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 999;
  background-color: #25d366;
  font-size: 3em;
  text-transform: capitalize;
  color: white;
  text-align: center;
}
.Home .bookingSent svg {
  font-size: 6em;
  color: white;
}
.Home .ContactUs {
  width: 100%;
  margin: 0 auto;
  margin-top: 1em;
  padding: 1em;
  max-width: 1330px;
}
@media screen and (max-width: 500px) {
  .Home .ContactUs {
    padding: 0;
    margin-top: 0;
  }
}
.Home .ContactUs .innerContactUs {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  height: 520px;
}
@media screen and (max-width: 500px) {
  .Home .ContactUs .innerContactUs {
    border-radius: 0;
  }
}
.Home .ContactUs .innerContactUs form {
  flex-grow: 1;
  background-image: linear-gradient(to left top, #25d366, #59de7e, #7ce996, #8ddb9c, #20ed46);
  z-index: 1;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 2em;
  padding-top: 1em;
  position: relative;
}
@media screen and (max-width: 500px) {
  .Home .ContactUs .innerContactUs form {
    min-width: 100%;
    padding: 1.5em;
    background-color: #25d366;
  }
}
.Home .ContactUs .innerContactUs form div {
  display: flex;
  flex-direction: column;
  color: white;
  margin-bottom: 1em;
}
.Home .ContactUs .innerContactUs form div label {
  padding-left: 0.3em;
  padding-bottom: 0.3em;
  display: flex;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
@media screen and (max-width: 500px) {
  .Home .ContactUs .innerContactUs form div label {
    font-size: 1.1em;
    color: black;
  }
}
.Home .ContactUs .innerContactUs form div input, .Home .ContactUs .innerContactUs form div textarea {
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 1em 1.5em;
}
.Home .ContactUs .innerContactUs form div textarea {
  height: 150px;
  border-radius: 10px;
  resize: none;
}
.Home .ContactUs .innerContactUs form h1 {
  color: white;
  margin-top: 0.5em;
  text-align: left;
  margin-bottom: 0.3em;
}
@media screen and (max-width: 500px) {
  .Home .ContactUs .innerContactUs form h1 {
    text-align: center;
    color: black;
    margin-bottom: 0.6em;
    margin-top: 0;
  }
}
.Home .ContactUs .innerContactUs form .submitInContact {
  position: relative;
  bottom: 1em;
  display: block;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 1em;
  margin-top: 1em;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .Home .ContactUs .innerContactUs form .submitInContact {
    background-color: black;
    color: #25d366;
  }
}
.Home .ContactUs .innerContactUs .contactUsSus {
  flex-grow: 2;
  padding-top: 2em;
  padding-left: 4em;
  position: relative;
  overflow: hidden;
  background: radial-gradient(at right center, rgb(0, 0, 0), rgb(0, 0, 0));
}
.Home .ContactUs .innerContactUs .contactUsSus h1 {
  color: white;
  font-size: 5em;
  max-width: 700px;
  line-height: 1.4em;
  text-transform: capitalize;
}
.Home .ContactUs .innerContactUs .contactUsSus span {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: white;
  outline: 10px dashed black;
  border-radius: 555px;
  top: -5em;
  right: -75px;
  filter: blur(230px);
}
.Home .ContactUs .innerContactUs .contactUsSus div {
  width: 250px;
  height: 200px;
  position: absolute;
  border: 70px solid rgba(255, 255, 255, 0.09);
  bottom: -3.8em;
  left: -5.8em;
  border-radius: 500px;
  transform: rotate(-40deg);
}
@media screen and (max-width: 700px) {
  .Home .ContactUs .innerContactUs .contactUsSus {
    display: none;
  }
}
.Home .ContactInfos {
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0.8em;
}
@media screen and (max-width: 500px) {
  .Home .ContactInfos {
    flex-direction: column;
    padding: 1em 0;
    margin-top: 0;
  }
}
.Home .ContactInfos p {
  color: white;
  display: flex;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
  font-size: 1.2em;
  text-transform: capitalize;
}
@media screen and (max-width: 500px) {
  .Home .ContactInfos p {
    text-align: left;
    width: 100%;
    padding-left: 0.5em;
    -moz-column-gap: 10px;
         column-gap: 10px;
    margin: 0.7em 0;
  }
}
.Home .ContactInfos p svg {
  color: #25d366;
  font-size: 1.25em;
}
.Home .pricing {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3em;
  row-gap: 10px;
  border: 0px solid black;
  padding-top: 3.5em;
  padding-bottom: 3.5em;
  background-image: linear-gradient(to left top, #25d366, #59de7e, #7ce996, #9bf4ad, #b8ffc5);
  overflow: hidden;
}
@media screen and (max-width: 500px) {
  .Home .pricing {
    margin-top: 0;
    border-radius: 0;
    padding-top: 1em;
    align-items: initial;
    background-color: #25d366;
    background-image: none;
    padding-bottom: 0;
  }
}
.Home .pricing > h1 {
  font-size: 5em;
  text-transform: capitalize;
  margin-bottom: 0.15em;
  margin-top: 0;
}
@media screen and (max-width: 500px) {
  .Home .pricing > h1 {
    font-size: 2.7em;
    text-align: center;
    color: white;
  }
}
.Home .pricing > span {
  width: 150px;
  height: 150px;
  border-radius: 80px;
  position: absolute;
  top: -3em;
  left: -3em;
  display: block;
  border: 40px solid white;
  background-color: transparent;
}
@media screen and (max-width: 500px) {
  .Home .pricing > span {
    display: none;
  }
}
.Home .pricing .pricingHolder {
  display: flex;
  justify-content: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 10px;
}
@media screen and (max-width: 500px) {
  .Home .pricing .pricingHolder {
    overflow-x: scroll;
    scrollbar-width: 0px;
    justify-content: start;
    contain: content;
    display: flex;
    flex-flow: row;
    max-height: 403px;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    scrollbar-width: none;
    padding: 1em;
    padding-top: 0;
  }
  .Home .pricing .pricingHolder::-webkit-scrollbar {
    display: none;
  }
}
.Home .pricing .pricingHolder div {
  width: 300px;
  height: 350px;
  background-size: cover;
  color: white;
  padding: 2em;
  font-size: 1em;
  border-radius: 20px;
  text-transform: capitalize;
  background-color: black;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 500px) {
  .Home .pricing .pricingHolder div {
    min-width: 300px;
    min-width: 220px;
    height: 226px;
  }
}
.Home .pricing .pricingHolder div span {
  position: absolute;
  top: -1em;
  width: 100px;
  height: 350px;
  background-color: rgba(37, 211, 102, 0.15);
  border-radius: 20px;
  left: 1.2em;
  display: block;
}
.Home .pricing .pricingHolder div label {
  font-size: 5em;
  margin-bottom: 1em;
  display: block;
  color: #25d366;
  position: relative;
  z-index: 99;
}
@media screen and (max-width: 500px) {
  .Home .pricing .pricingHolder div label {
    color: white;
    font-size: 3em;
    margin-bottom: 0.5em;
  }
}
.Home .pricing .pricingHolder div p {
  font-size: 1.7em;
  margin-bottom: 0;
  margin-top: 0.3em;
  display: flex;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
  position: relative;
  z-index: 99;
}
@media screen and (max-width: 500px) {
  .Home .pricing .pricingHolder div p {
    font-size: 1.2em;
    margin-bottom: 0;
    margin-top: 0.3em;
    display: flex;
    align-items: center;
    -moz-column-gap: 5px;
         column-gap: 5px;
    position: relative;
    z-index: 99;
  }
}
.Home .pricing .pricingHolder div p svg {
  color: #25d366;
}
.Home .pricing .pricingHolder2 {
  width: 100%;
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  max-width: 920px;
}
@media screen and (max-width: 500px) {
  .Home .pricing .pricingHolder2 {
    flex-direction: column;
  }
}
.Home .pricing .pracingHeureHolder {
  width: 100%;
  max-width: 920px;
  height: 300px;
  background-size: cover;
  color: white;
  padding: 2em;
  font-size: 1em;
  border-radius: 20px;
  text-transform: capitalize;
  background-color: black;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.Home .pricing .pracingHeureHolder:nth-child(1) {
  border-bottom: 1px solid #25d366;
}
@media screen and (max-width: 500px) {
  .Home .pricing .pracingHeureHolder {
    border-radius: 0;
    height: 178px;
  }
}
.Home .pricing .pracingHeureHolder span {
  position: absolute;
  top: -1em;
  width: 100px;
  height: 290px;
  background-color: rgba(37, 211, 102, 0.15);
  border-radius: 20px;
  left: 1.5em;
  display: block;
}
@media screen and (max-width: 500px) {
  .Home .pricing .pracingHeureHolder span {
    left: 2em;
  }
}
.Home .pricing .pracingHeureHolder label {
  font-size: 3.5em;
  margin-bottom: 1.5em;
  display: block;
  color: #25d366;
  position: relative;
  z-index: 99;
}
@media screen and (max-width: 500px) {
  .Home .pricing .pracingHeureHolder label {
    font-size: 2em;
    text-align: center;
    margin-bottom: 0.5em;
    color: white;
  }
}
.Home .pricing .pracingHeureHolder p {
  font-size: 2.3em;
  margin-bottom: 0;
  margin-top: 0.3em;
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  position: relative;
  z-index: 99;
}
@media screen and (max-width: 500px) {
  .Home .pricing .pracingHeureHolder p {
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
}
.Home .pricing .pracingHeureHolder p svg {
  color: #25d366;
}
.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: black;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader img {
  width: 200px;
  animation: scaleUpDown 2s infinite; /* Apply the animation to the image */
}

@keyframes scaleUpDown {
  0%, 100% {
    transform: scale(1); /* Start and end at original size */
  }
  50% {
    transform: scale(1.1); /* Scale up to 1.5 times the original size */
  }
}
.checkbox-wrapper-9 .tgl {
  display: none;
}

.checkbox-wrapper-9 .tgl,
.checkbox-wrapper-9 .tgl:after,
.checkbox-wrapper-9 .tgl:before,
.checkbox-wrapper-9 .tgl *,
.checkbox-wrapper-9 .tgl *:after,
.checkbox-wrapper-9 .tgl *:before,
.checkbox-wrapper-9 .tgl + .tgl-btn {
  box-sizing: border-box;
}

.checkbox-wrapper-9 .tgl::-moz-selection, .checkbox-wrapper-9 .tgl:after::-moz-selection, .checkbox-wrapper-9 .tgl:before::-moz-selection, .checkbox-wrapper-9 .tgl *::-moz-selection, .checkbox-wrapper-9 .tgl *:after::-moz-selection, .checkbox-wrapper-9 .tgl *:before::-moz-selection, .checkbox-wrapper-9 .tgl + .tgl-btn::-moz-selection {
  background: none;
}

.checkbox-wrapper-9 .tgl::-moz-selection,
.checkbox-wrapper-9 .tgl:after::-moz-selection,
.checkbox-wrapper-9 .tgl:before::-moz-selection,
.checkbox-wrapper-9 .tgl *::-moz-selection,
.checkbox-wrapper-9 .tgl *:after::-moz-selection,
.checkbox-wrapper-9 .tgl *:before::-moz-selection,
.checkbox-wrapper-9 .tgl + .tgl-btn::-moz-selection,
.checkbox-wrapper-9 .tgl::selection,
.checkbox-wrapper-9 .tgl:after::selection,
.checkbox-wrapper-9 .tgl:before::selection,
.checkbox-wrapper-9 .tgl *::selection,
.checkbox-wrapper-9 .tgl *:after::selection,
.checkbox-wrapper-9 .tgl *:before::selection,
.checkbox-wrapper-9 .tgl + .tgl-btn::selection {
  background: none;
}

.checkbox-wrapper-9 .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 1.5em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.checkbox-wrapper-9 .tgl + .tgl-btn:after,
.checkbox-wrapper-9 .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.checkbox-wrapper-9 .tgl + .tgl-btn:after {
  left: 0;
}

.checkbox-wrapper-9 .tgl + .tgl-btn:before {
  display: none;
}

.checkbox-wrapper-9 .tgl:checked + .tgl-btn:after {
  left: 50%;
}

.checkbox-wrapper-9 .tgl-flat + .tgl-btn {
  padding: 2px;
  transition: all 0.2s ease;
  background: #fff;
  border: 4px solid black;
  border-radius: 2em;
  margin: 0 auto;
}

.checkbox-wrapper-9 .tgl-flat + .tgl-btn:after {
  transition: all 0.2s ease;
  background: black;
  content: "";
  border-radius: 1em;
}

.checkbox-wrapper-9 .tgl-flat:checked + .tgl-btn {
  border: 4px solid #25d366;
}

.checkbox-wrapper-9 .tgl-flat:checked + .tgl-btn:after {
  left: 50%;
  background: #25d366;
}

.adminLogin {
  width: 100%;
  background-color: #25d366;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
}
.adminLogin h1 {
  font-size: 4em;
  margin-top: 0;
}
@media screen and (max-width: 500px) {
  .adminLogin h1 {
    text-align: center;
    font-size: 2em;
  }
}
.adminLogin svg {
  font-size: 10em;
}
@media screen and (max-width: 500px) {
  .adminLogin svg {
    font-size: 7em;
  }
}
.adminLogin form {
  background-color: white;
  border-radius: 50px;
  padding: 0.5em;
  display: flex;
}
.adminLogin form input {
  font-size: 1em;
  width: 100%;
  min-width: 550px;
  padding: 0.5em;
  outline: none;
  border: none;
  border-radius: 50px;
  padding-left: 1em;
  text-transform: capitalize;
}
@media screen and (max-width: 500px) {
  .adminLogin form input {
    min-width: unset;
  }
}
.adminLogin form button {
  background-color: #25d366;
  padding: 1em 2em;
  outline: none;
  border: none;
  width: 200px;
  border-radius: 50px;
  cursor: pointer;
}

.AdminDash {
  width: 100%;
  position: relative;
  display: flex;
  height: 100vh;
}
@media screen and (max-width: 850px) {
  .AdminDash {
    flex-direction: column;
  }
}
.AdminDash .sideBar {
  width: 348px;
  height: 100vh;
  padding: 1em 1.5em;
  background-color: #25d366;
  position: relative;
}
.AdminDash .sideBar h1 {
  color: black;
  text-align: center;
  font-size: 3em;
}
.AdminDash .sideBar div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  margin-top: 3em;
}
.AdminDash .sideBar div a {
  background-color: black;
  width: 100%;
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 1em;
  border-radius: 10px;
  -moz-column-gap: 10px;
       column-gap: 10px;
  font-size: 1.2em;
}
.AdminDash .sideBar div a svg {
  font-size: 1.5em;
}
.AdminDash .sideBar h2 {
  position: absolute;
  bottom: 0;
  color: black;
  font-size: 0.8em;
  text-align: center;
  left: 0;
  text-transform: capitalize;
  width: 100%;
}
@media screen and (max-width: 850px) {
  .AdminDash .sideBar {
    position: absolute;
    left: -400px;
    bottom: 0;
  }
}
.AdminDash .mobileNavBar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding: 0.2em 0.5em;
}
@media screen and (min-width: 850px) {
  .AdminDash .mobileNavBar {
    display: none;
  }
}
.AdminDash .mobileNavBar h1 {
  color: white;
  font-size: 1.1em;
}
.AdminDash .mobileNavBar div {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.AdminDash .mobileNavBar div a {
  font-size: 0.95em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  text-decoration: none;
  padding: 0.5em;
  border-radius: 5px;
  -moz-column-gap: 3px;
       column-gap: 3px;
}
.AdminDash .requestsHolder {
  flex-grow: 1;
  padding: 1em;
  height: 100vh;
  scrollbar-width: 0px;
  overflow-y: scroll;
}
.AdminDash .requestsHolder::-webkit-scrollbar {
  display: none;
}
.AdminDash .requestsHolder .header {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5em;
}
.AdminDash .requestsHolder .header h1 {
  background-color: black;
  color: white;
  padding: 0.35em 1em;
  border-radius: 10px;
  font-size: 1.5em;
  flex-grow: 1;
  text-align: center;
  margin: 0;
}
@media screen and (max-width: 850px) {
  .AdminDash .requestsHolder .header div {
    display: flex;
  }
}
.AdminDash .requestsHolder .header div button {
  background-color: black;
  color: white;
  text-align: center;
  outline: none;
  border: none;
  padding: 0.5em 1em;
  height: 100%;
  font-size: 1.1em;
  margin: 0 0.2em;
  border-radius: 10px;
  text-transform: capitalize;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;
  -moz-column-gap: 5px;
       column-gap: 5px;
  align-items: center;
}
.AdminDash .requestsHolder .header div button:hover {
  background-color: #25d366;
}
@media screen and (max-width: 850px) {
  .AdminDash .requestsHolder .header div button {
    flex-grow: 1;
  }
}
@media screen and (max-width: 850px) {
  .AdminDash .requestsHolder .header {
    flex-direction: column;
    row-gap: 10px;
  }
}
.AdminDash .requestsHolder .searchSection {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  padding-bottom: 0.5em;
  border-radius: 10px;
  padding: 2em 1em;
}
.AdminDash .requestsHolder .searchSection h1 {
  font-size: 4em;
  text-transform: capitalize;
  color: white;
  margin-top: 0;
  margin-bottom: 0.1em;
}
@media screen and (max-width: 850px) {
  .AdminDash .requestsHolder .searchSection h1 {
    font-size: 1.3em;
  }
}
.AdminDash .requestsHolder .searchSection div {
  width: 100%;
  display: flex;
  background-color: white;
  max-width: 1000px;
  padding: 0.5em 1em;
  border-radius: 10px;
  align-items: center;
}
.AdminDash .requestsHolder .searchSection div svg {
  color: black;
  font-size: 2em;
}
.AdminDash .requestsHolder .searchSection div input {
  flex-grow: 1;
  outline: none;
  border: none;
  padding-left: 1em;
  text-transform: capitalize;
}
@media screen and (max-width: 850px) {
  .AdminDash .requestsHolder .searchSection div input {
    font-size: 0.75em;
  }
}
.AdminDash .requestsHolder .searchSection div select {
  outline: none;
  border: none;
  font-size: 1em;
  background-color: #25d366;
  padding: 0.5em 0.5em;
  border-radius: 10px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  text-align: center;
}
@media screen and (max-width: 850px) {
  .AdminDash .requestsHolder .searchSection div select {
    font-size: 0.7em;
  }
}
.AdminDash .requestsHolder .reqCardsHolder {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  border-radius: 10px;
  margin: 0;
  padding: 0.5em 0em;
  justify-content: center;
  align-items: center;
  list-style: none;
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 10px;
}
.AdminDash .requestsHolder .reqCardsHolder li {
  margin: 0;
  padding: 1em;
  background-color: black;
  border-radius: 10px;
  width: 100%;
  max-width: 596px;
  color: white;
}
.AdminDash .requestsHolder .reqCardsHolder li .strong {
  width: 100%;
  display: flex;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
.AdminDash .requestsHolder .reqCardsHolder li .strong label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #25d366;
  border-radius: 5px;
  padding: 5px 10px;
  color: black;
  -moz-column-gap: 5px;
       column-gap: 5px;
  font-size: 1.1em;
}
@media screen and (max-width: 850px) {
  .AdminDash .requestsHolder .reqCardsHolder li .strong label:nth-child(2) {
    font-size: 0.75em;
  }
}
.AdminDash .requestsHolder .reqCardsHolder li .strong label:nth-child(1) {
  flex-grow: 1;
  text-align: center;
}
.AdminDash .requestsHolder .reqCardsHolder li .underStrong {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
}
.AdminDash .requestsHolder .reqCardsHolder li .underStrong label {
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  font-size: 1.2em;
}
.AdminDash .requestsHolder .reqCardsHolder li .underStrong label svg {
  color: #25d366;
}
.AdminDash .requestsHolder .reqCardsHolder li .inCar {
  width: 100%;
  display: flex;
  -moz-column-gap: 5px;
       column-gap: 5px;
  margin-bottom: 10px;
}
.AdminDash .requestsHolder .reqCardsHolder li .inCar label {
  background-color: #25d366;
  flex-grow: 1;
  display: flex;
  color: black;
  padding: 5px;
  border-radius: 5px;
  -moz-column-gap: 3px;
       column-gap: 3px;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
}
.AdminDash .requestsHolder .reqCardsHolder li .aboutUser {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.AdminDash .requestsHolder .reqCardsHolder li .aboutUser label {
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  font-size: 1.2em;
}
.AdminDash .requestsHolder .reqCardsHolder li .aboutUser label svg {
  color: #25d366;
}
.AdminDash .requestsHolder .reqCardsHolder li .doneBtn {
  width: 100%;
  background-color: white;
  outline: 0;
  border: none;
  border-radius: 5px;
  padding: 7px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
  font-size: 1.05em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.AdminDash .requestsHolder .reqCardsHolder li .doneBtn:hover {
  background-color: #25d366;
  color: white;
}
.AdminDash .requestsHolder .reqCardsHolder li .doneBtn svg {
  font-size: 1.4em;
}
.AdminDash .messagesHolder {
  width: 100%;
  padding: 1em;
}
.AdminDash .messagesHolder > h1 {
  background-color: black;
  text-align: center;
  border-radius: 10px;
  color: white;
  padding: 0.5em;
  margin: 0;
  font-size: 1.8em;
  margin-bottom: 10px;
}
.AdminDash .messagesHolder .searchSection {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  padding-bottom: 0.5em;
  border-radius: 10px;
  padding: 2em 1em;
}
.AdminDash .messagesHolder .searchSection h1 {
  font-size: 4em;
  text-transform: capitalize;
  color: white;
  margin-top: 0;
  margin-bottom: 0.1em;
}
@media screen and (max-width: 850px) {
  .AdminDash .messagesHolder .searchSection h1 {
    font-size: 1.3em;
  }
}
.AdminDash .messagesHolder .searchSection div {
  width: 100%;
  display: flex;
  background-color: white;
  max-width: 1000px;
  padding: 0.5em 1em;
  border-radius: 10px;
  align-items: center;
}
.AdminDash .messagesHolder .searchSection div svg {
  color: black;
  font-size: 2em;
}
.AdminDash .messagesHolder .searchSection div input {
  flex-grow: 1;
  outline: none;
  border: none;
  padding-left: 1em;
  text-transform: capitalize;
}
@media screen and (max-width: 850px) {
  .AdminDash .messagesHolder .searchSection div input {
    font-size: 0.75em;
  }
}
.AdminDash .messagesHolder .searchSection div select {
  outline: none;
  border: none;
  font-size: 1em;
  background-color: #25d366;
  padding: 0.5em 0.5em;
  border-radius: 10px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  text-align: center;
}
@media screen and (max-width: 850px) {
  .AdminDash .messagesHolder .searchSection div select {
    font-size: 0.7em;
  }
}
.AdminDash .msgCardsHolder {
  width: 100%;
  list-style: none;
  padding: 0;
}
.AdminDash .msgCardsHolder li {
  width: 100%;
  max-width: 350px;
  background-color: black;
  border-radius: 10px;
  color: white;
  padding: 1em;
}
.AdminDash .msgCardsHolder li .aboutUser {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.AdminDash .msgCardsHolder li .aboutUser label {
  display: flex;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
  background-color: #25d366;
  padding: 0.5em 0.5em;
  border-radius: 5px;
  color: black;
}
.AdminDash .msgCardsHolder li .aboutUser label svg {
  color: white;
}
.AdminDash .msgCardsHolder li .messageContent label {
  display: flex;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
  background-color: #25d366;
  padding: 0.5em 0.5em;
  border-radius: 5px;
  color: black;
}
.AdminDash .msgCardsHolder li .messageContent label svg {
  color: white;
}
.AdminDash .msgCardsHolder li .readBtn {
  width: 100%;
  background-color: white;
  color: black;
  outline: none;
  border: none;
  padding: 0.5em;
  border-radius: 5px;
  margin-top: 5px;
  cursor: pointer;
}/*# sourceMappingURL=main.css.map */